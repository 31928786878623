@import 'src/styles/mixins';
@import 'src/styles/variables';

.content {
  color: color(black50);

  @include use-spacings(margin-top, m);
  @include small-text();
}

.link {
  @include link-style();
}
