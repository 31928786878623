@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/functions';

.wrapper {
  @include use-spacings(margin-top, l);

  position: relative;

  @media (min-width: $tablet-size) {
    @include use-spacings(margin-top, 120px);
  }

  @media (min-width: $desktop-size) {
    @include use-spacings(margin-top, xl);
  }
}

.app {
  // https://gitlab.com/creditclubteam/projects/platform/-/issues/922
  // @include flex-columns(2, row, 0);

  @include use-spacings(margin, auto);
  @include use-spacings(margin-top, l);
  @include use-spacings(padding, m);
  @include big-body-text();

  position: relative;
  z-index: 10;
  flex-wrap: wrap;
  max-width: 592px;
  border-radius: 16px;
  box-shadow: 0 18px 65px rgba(0, 0, 0, 0.07), 0 4.02054px 6.37957px rgba(0, 0, 0, 0.0287811), 0 1.19702px 0 rgba(0, 0, 0, 0.0082118);

  @media (min-width: $tablet-size) {
    @include use-spacings(margin-right, 100%);

    min-width: 480px;
    margin-left: 0;
  }

  @media (min-width: $desktop-size) {
    min-width: 592px;
  }
}

.header {
  @include flex-columns(1, row, 0);
  @include use-spacings(margin-bottom, s);

  justify-content: space-between;
  width: 100%;

  svg {
    width: 64px;
    height: 64px;
  }
}

.title {
  @include h4-text();
}

.footer {
  @include flex-columns(2, row, 0);

  flex-wrap: wrap;
}

.link {
  width: 180px !important;
  height: 52px;
  min-height: 52px;
  padding: 0 !important;
  background-color: transparent;
  border: none;

  @include use-spacings(margin-top, s);
  @include use-spacings(margin-right, s);

  svg {
    width: 100%;
    height: 100%;
  }
}

.img {
  position: absolute;
  right: 16px;
  bottom: -20px;
  z-index: 1;
  display: none;

  @media (min-width: $tablet-size) {
    display: flex;
  }

  @media (min-width: $desktop-size) {
    right: -44px;
    bottom: -44px;
  }
}
