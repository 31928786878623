@import 'variables';
@import 'functions';

@mixin animate($name, $duration, $iteration, $direction, $timing) {
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $timing;
  animation-iteration-count: $iteration;
  animation-direction: $direction;
}

@mixin flex($justifyContent: flex-start, $alignItems: center, $direction: initial, $display: flex) {
  display: $display;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin columns($columnCount: 2, $columnGap: $column-gap) {
  column-count: $columnCount;
  column-gap: $columnGap;

  > * {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

/**
 * Создаёт колоночный макет
 * Каждая колонка должна быть на первом уровне детей элемента к которому пременяется примесь
 * В качестве межколоночного расстояния используются именованные размеры
 */
@mixin flex-columns($count: 2, $flex-direction: row, $gap: $column-gap) {
  display: flex;
  flex-direction: $flex-direction;

  > * {
    width: calc(100% / #{$count} - #{$gap} / 2);

    @if ($flex-direction == row) {
      &:not(:first-child) {
        margin-left: $gap;
      }
    }

    @if ($flex-direction == row-reverse) {
      &:not(:last-child) {
        margin-left: $gap;
      }
    }
  }
}

@mixin grid-columns($count: 2, $gap: l) {
  display: grid;
  grid-template-columns: repeat($count, 1fr);

  @include use-spacings(grid-gap, $gap);
}

@mixin body-text() {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  @media (min-width: $mobile-size) {
    font-size: 20px;
    line-height: 28px;
  }
}

@mixin big-body-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;

  @media (min-width: $mobile-size) {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin extra-small-text() {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  @media (min-width: $mobile-size) {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin medium-bold-text() {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

@mixin medium-text() {
  font-size: 18px;
  line-height: 24px;
}

@mixin big-bold-text() {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;

  @media (min-width: $mobile-size) {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin small-text() {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

@mixin light-text() {
  opacity: 0.5;
}

@mixin small-light-text() {
  opacity: 0.5;

  @include extra-small-text();

  @media (min-width: $tablet-size) {
    @include small-text();
  }
}

@mixin link-text() {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  @media (min-width: $mobile-size) {
    font-size: 20px;
    line-height: 28px;
  }
}

@mixin link-style() {
  color: color('main');

  &:hover {
    color: color('buttonhover');
  }
}

@mixin h1-text() {
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;

  @media (min-width: $mobile-size) {
    font-size: 56px;
    line-height: 64px;
  }
}

@mixin h2-text() {
  font-size: 28px;
  font-weight: 800;
  line-height: 34px;

  @media (min-width: $mobile-size) {
    font-size: 40px;
    line-height: 48px;
  }
}

@mixin h3-text() {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;

  @media (min-width: $mobile-size) {
    font-size: 32px;
    line-height: 40px;
  }
}

@mixin h4-text() {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;

  @media (min-width: $mobile-size) {
    font-size: 28px;
    line-height: 32px;
  }
}

@mixin h5-text() {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;

  @media (min-width: $mobile-size) {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin use-spacings($propName, $args...) {
  $valueMobile: null;
  $valueDesktop: null;

  @each $arg in $args {
    $valueDesktop: $valueDesktop + spacing($arg, $ui-spacings-desktop) + ' ';
    $valueMobile: $valueMobile + spacing($arg, $ui-spacings-mobile) + ' ';
  }

  #{$propName}: $valueMobile;

  @if ($valueMobile != $valueDesktop) {
    @media (min-width: $mobile-size) {
      #{$propName}: $valueDesktop;
    }
  }
}

@mixin use-negative-spacings($propName, $args...) {
  $valueMobile: null;
  $valueDesktop: null;

  @each $arg in $args {
    $valueDesktop: $valueDesktop + -+ spacing($arg, $ui-spacings-desktop) + ' ';
    $valueMobile: $valueMobile + -+ spacing($arg, $ui-spacings-mobile) + ' ';
  }

  #{$propName}: $valueMobile;

  @if ($valueMobile != $valueDesktop) {
    @media (min-width: $mobile-size) {
      #{$propName}: $valueDesktop;
    }
  }
}

@mixin main-link() {
  color: color(main);

  &:hover {
    color: color(buttonhover);
    cursor: pointer;
  }

  &:active {
    color: color(buttonhover);
  }
}

@mixin min-max-size($height) {
  min-width: $height;
  max-width: $height;
  min-height: $height;
  max-height: $height;
}
