@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-width: $mobile-size) {
    display: flex;
    height: 56px;
  }
}

.content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
