@import 'src/styles/functions';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrapSocial {
  display: grid;
  grid-template-areas:
    "logo social"
    "contacts contacts"
    "login login";
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 20px 20px;

  @media (min-width: $extra-mobile-size) {
    grid-template-areas:
      "logo social"
      "contacts login";
    gap: 40px 0;
  }

  @media (min-width: $tablet-size) {
    grid-template-areas: "logo social contacts login";
    grid-template-columns: repeat(4, 1fr);
    gap: 0 0;
  }
}

.logoWrap {
  grid-area: logo;

  a {
    display: flex;
    align-items: center;
  }

  svg {
    width: min-content;
    height: 32px;
  }
}

.social {
  display: flex;
  grid-area: social;
  align-items: center;

  @media (min-width: $tablet-size) {
    align-items: flex-start;
  }

  svg {
    width: auto !important;
    height: 24px;
  }
}

.contacts {
  grid-area: contacts;

  a {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}

.login {
  grid-area: login;
}

.appDesc {
  display: none;
}

.wrapDescriptionApp {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  color: color(black50);

  > div {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    @media (min-width: $tablet-size) {
      width: calc(50% - 16px);
    }
  }

  .logos {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (min-width: $mobile-size) {
      flex-direction: row;
    }
  }

  .logos-item {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: min-content;

    @media (min-width: $mobile-size) {
      justify-content: flex-start;
    }

    svg {
      width: min-content;
    }

    span {
      @include use-spacings(padding-left, 16px);

      width: 236px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.nav {
  @include use-spacings(margin, 'l', 0, 'l');

  @media (min-width: $extra-mobile-size) {
    @include use-spacings(margin, 'l', 0, 'xl');
  }

  @media (min-width: $mobile-size) {
    display: grid;
    grid-template-areas:
      "first third"
      "second third"
      "second fourth";
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: auto;
    gap: 0 0;
  }

  @media (min-width: $tablet-size) {
    grid-template-areas: "first second third fourth";
    grid-template-columns: repeat(4, 1fr);
  }
}

.first {
  grid-area: first;
}

.second {
  grid-area: second;
}

.third {
  grid-area: third;
}

.fourth {
  grid-area: fourth;
}

.nav-list {
  @media (min-width: 540px) {
    @include use-spacings(margin-bottom, xs);
  }

  @media (min-width: $mobile-size) {
    width: 157px;
    margin-bottom: initial;
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: $tablet-size) {
    width: 220px;
  }

  @media (min-width: $desktop-size) {
    width: 170px;
  }
}

.nav-item,
.nav-sub-list {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 18px;

  @media (min-width: $mobile-size) {
    display: block;
    margin-right: 0;
    white-space: nowrap;
  }

  @media (min-width: $tablet-size) {
    grid-template-areas: "first second third fourth";
    grid-template-columns: repeat(4, 1fr);
    white-space: normal;
  }

  @media (min-width: $desktop-size) {
    font-size: 14px;
    white-space: nowrap;
  }
}

.wrapRowInformation {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: color(black50);

  @include use-spacings(margin-top, l);

  @media (min-width: $tablet-size) {
    gap: 32px;
  }

  > div {
    width: 100%;

    @media (min-width: $tablet-size) {
      width: calc(50% - 16px);
    }
  }
}

.currentCity {
  color: color(main);

  @include use-spacings(margin-left, 15px);
}

.cityRow {
  display: flex;
}

.link {
  color: color(main);
}
