@import 'src/styles/variables';
@import 'src/styles/mixins';

.spoiler-button {
  @include flex();
}

.spoiler-arrow {
  width: 10px;
  height: 10px;

  @include use-spacings(margin-left, xs);
}

.spoiler-open {
  .spoiler-arrow {
    transform: rotate(180deg);
  }
}

.spoiler-content {
  @include use-spacings(margin-top, xs);
}
