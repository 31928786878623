@import '../../../../../../styles/functions';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';

.submenu {
  position: absolute;
  top: 75%;
  left: 0;
  display: none;
  min-width: 270px;
  background: #fff;
  border-radius: $base-border-radius;
  box-shadow: $card-shadow;

  @include use-spacings(padding, 's');
}

.submenu-item {
  &:not(:last-child) {
    @include use-spacings(margin-bottom, 's');
  }

  color: color('black50');

  &:hover {
    color: color('mainblack');
  }
}

[data-submenu-active='true'] {
  .submenu {
    display: initial;
  }
}
