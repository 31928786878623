@import 'src/styles/variables';
@import 'src/styles/functions';
@import 'src/styles/mixins';

.button {
  color: #fff;
  cursor: pointer;
  background: color('main');
  border-radius: 8px;

  &:hover {
    background: color('buttonhover');
  }

  &:active {
    background: color('buttonpressed');
  }

  @include flex(center);
}

.size-s {
  height: 32px;
}

.size-m {
  height: 56px;
}

.size-s,
.size-m {
  font-size: 18px;
  line-height: 24px;
}

.size-l {
  height: 64px;
  font-size: 24px;
  line-height: 32px;
}

.disabled {
  cursor: initial;
  background: color('grey');
}

@media (min-width: $mobile-size) {
  .button {
    width: auto;
  }

  .size-s {
    padding: 0 16px 1px;
  }

  .size-m {
    padding: 0 12px 1px;
  }

  .size-l {
    padding: 0 20px 1px;
  }
}
