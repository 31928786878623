$ui-colors: (
  mainblack: #000,
  mainwhite: #fff,
  main: #00a1dc,
  pacificblue: #c2e9ff,
  bluealice: #edf8ff,
  blueDark: #262d47,
  wheat: #f7e1bc,
  oldlace: #f7f2ea,
  green: #53a770,
  greenbright: #62e6ac,
  greenlight: #d7f9ea,
  bluegray: #d5e0e0,
  graylight: #fcfcfc,
  lightmint: #f2f5f4,
  gray4: #bdbdbd,
  error: #fd2c2c,
  darkgrey: #36445e,
  buttonhover: #33b4e3,
  buttonpressed: #0099d1,
  disabled: #bdbdbd,
  black30: rgb(0 0 0 / 30%),
  black50: rgb(0 0 0 / 50%),
  border: #f2f2f2,
);

$spacings-types: (xs, s, m, l, xl);

$ui-spacings-mobile: (
  xs: 8px,
  s: 16px,
  m: 24px,
  l: 32px,
  xl: 56px,
);

$ui-spacings-desktop: (
  xs: 8px,
  s: 16px,
  m: 24px,
  l: 40px,
  xl: 80px,
);

$base-border-radius: 8px;
$base-transition: 0.2s cubic-bezier(0.44, 0.3, 0.29, 0.99);
$column-gap: 32px;

// shadows
$card-shadow: 0 0 40px rgba(0, 0, 0, 0.07), 0 4px 6.38px rgba(0, 0, 0, 0.02), 0 1.19px 0 rgba(0, 0, 0, 0.008);

// sizes
$desktop-size: 1200px;
$tablet-size: 1024px;
$mobile-size: 768px;
$extra-mobile-size: 540px;
$min-screen-size: 320px;

$top-menu-show-nav-breakpoint: 1070px;

// menu
$mobile-menu-height: 72px;
$fixed-menu-height: 72px;
$desktop-menu-height: 88px;

$mobile-menu-logo-width: 150px;

// animate
$hoverTansition: $base-transition;
