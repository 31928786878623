@import 'src/styles/functions';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.wrap {
  text-align: left;

  > div {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    @include use-spacings(margin-bottom, 10px);

    @media (min-width: $tablet-size) {
      @include use-spacings(margin-bottom, 0);
    }
  }

  .title {
    margin-bottom: 8px;
    font-size: 18px;
    color: color(mainblack);
  }

  .links {
    display: flex;
    gap: 8px;
    color: color(mainblack);

    .link {
      margin-right: 5px;
    }

    :hover .link {
      color: color(main);
    }
  }
}
