@import '../../../../../styles/mixins';

.wrap {
  @include use-spacings(padding-left, 's');

  svg {
    width: 32px;
    height: 32px;
  }
}
