@import '../../../../../styles/mixins';
@import '../../../../../styles/functions';
@import '../../../../../styles/variables';

.wrap {
  @include flex();

  position: relative;
  height: 100%;

  @include use-spacings(margin-left, 's');
}

.dropdown {
  @include small-text();
  @include flex(flex-start, flex-start, column);

  position: absolute;
  top: 100%;
  right: 0;
  width: 176px;

  @include use-spacings(padding, 's');

  background: color('mainwhite');
  border-radius: $base-border-radius;
  box-shadow: $card-shadow;

  a {
    color: color('black50');

    &:not(:last-child) {
      @include use-spacings(margin-bottom, 's');
    }

    &:hover {
      color: color('mainblack');
    }
  }
}
