@import 'src/styles/mixins';
@import 'src/styles/functions';

.wrapper {
  @include flex(center, center, column);

  h1 {
    text-align: center;

    @include use-spacings('margin-top', 'm');
  }
}

.icon {
  width: 100px;
  height: 100px;
  fill: color('black50');
}

.go-home {
  color: color('main');

  @include use-spacings('margin-top', 'l');
}
